import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import logo from './logo.svg';
import './App.css';

import 'rsuite/dist/rsuite.min.css';


import SidenavBar from './components/SidenavBar';

import { ReactKeycloakProvider, useKeycloak } from '@react-keycloak/web'
import KeyCloack from './services/KeyCloak'

import { AppRouter } from './AppRouter';

function App() {
  return (
    <ReactKeycloakProvider authClient={KeyCloack}  initOptions={{
      onLoad: "login-required", checkLoginIframe: true
  }}>
        <div className="App">     
          <AppRouter />    
        </div>
    </ReactKeycloakProvider>     
  );
}

export default App;
