
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  MDBContainer,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBIcon,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBBtn,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBCollapse,
  MDBInputGroup,
} from 'mdb-react-ui-kit';



export default function App() {
  const [openBasic, setOpenBasic] = useState(false);

  return (
    <MDBNavbar expand='xl' light bgColor='light'>
      <MDBContainer fluid>
        <Link to='/'>
          <MDBNavbarBrand href='#'><img src="/logo.png" height="100px" className="d-inline-block align-top" alt="" />Gereformeerde<br />Gemeente<br />Zoetermeer</MDBNavbarBrand>
        </Link>
     </MDBContainer>
    </MDBNavbar>
  );
}