import React, { useState, useEffect }  from "react";
import {View, Alert, Switch, StyleSheet, Pressable, Text} from 'react-native';
import { useKeycloak } from '@react-keycloak/web';
import OneSignal from 'react-onesignal';

//let permission = await OneSignal.Notifications.permission;


export default function NotificatieSettings() {

 
  const [isInitiated, setisInitiated] = useState(false);
  const [isPermissionSet, setIsPermissionSet] = useState(false);

  const [isVerjaardagenEnabled, setIsVerjaardagenEnabled] = useState(false);
  const toggleSwitchVerjaardagen = () =>  {
    setIsVerjaardagenEnabled(previousState => !previousState);    
  };

  const [isNieuwsEnabled, setIsNieuwsEnabled] = useState(false);
  const toggleSwitchNieuws = () => { setIsNieuwsEnabled(previousState => !previousState);};

  const [isDMEnabled, setIsDMEnabled] = useState(false);
  const toggleSwitchDM = () => setIsDMEnabled(previousState => !previousState);


  const {keycloak} = useKeycloak();

  const Separator = () => <View style={styles.separator} />;

  useEffect(() => {

    const setInitialPermission = () => {
      const granted = OneSignal.Notifications.permission; // this will be a boolean
      console.log('INITIAL PERMISSION', granted);
    };

    setIsPermissionSet(OneSignal.Notifications.permission);
;


}, [isInitiated])


  
  useEffect(() => {
      
      OneSignal.init({ appId: process.env.REACT_APP_ONESIGNAL_APPID, allowLocalhostAsSecureOrigin: true}).then(() => {
        OneSignal.Slidedown.promptPush();
        OneSignal.login(keycloak.tokenParsed?.sub);     
        
        setisInitiated(true);
        var user = OneSignal.User;
        console.log(user);
        console.log(user.PushSubscription.id);
        
      });
        



      
  

  }, [])





  return(!isInitiated ? 
    (<><div>Bezig met laden.</div></>)
  : 
   (
      <div className="notificationSettings">

        <View style={styles.container}>
          <View style={styles.sub}>
            <Text><h3>Notificaties</h3></Text>
          </View>
          <View style={styles.sub}>
            <Text><b>Voor het gebruik van notificaties dient u eenmalig toestemming te geven.</b></Text>
          </View>
          
          <View style={styles.sub}>
            <Text>Notificaties toestaan op dit apparaat:</Text>
            <Switch
                trackColor={{false: '#767577', true: '#81b0ff'}}
                thumbColor={isPermissionSet ? '#f5dd4b' : '#f4f3f4'}
                ios_backgroundColor="#3e3e3e"
                onValueChange={subscribe}
                value={isPermissionSet}
                disabled={isPermissionSet}
              /><br />
              <Pressable onPress={sendTestNotification}>
                <Text> Stuur test notificatie</Text>
                </Pressable>
          </View>
          <View style={styles.sub}>
              <Text  style={styles.small}><i>Intrekken van toestemming is mogelijk via de instellingen van uw browser of mobiele apparaat.</i></Text>
          </View>
          <Separator />             
          <View disabled={!isPermissionSet}  aria-hidden={!isPermissionSet}>
            <View style={styles.sub}>
              <Text><b>Welke notificaties wilt u ontvangen?</b></Text>
            </View>
            <View style={styles.sub}>
              <Text>Verjaardagen (70+) per dag:</Text>
              <Switch
                trackColor={{false: '#767577', true: '#81b0ff'}}
                thumbColor={isVerjaardagenEnabled ? '#f5dd4b' : '#f4f3f4'}
                ios_backgroundColor="#3e3e3e"
                onValueChange={toggleSwitchVerjaardagen}
                value={isVerjaardagenEnabled}
                disabled={!isPermissionSet}
              />
            </View>
          
            <View style={styles.sub}>
              <Text>Nieuws:</Text>
              <Switch
                trackColor={{false: '#767577', true: '#81b0ff'}}
                thumbColor={isNieuwsEnabled ? '#f5dd4b' : '#f4f3f4'}
                ios_backgroundColor="#3e3e3e"
                onValueChange={toggleSwitchNieuws}
                value={isNieuwsEnabled}
                disabled={!isPermissionSet}
              />
            </View>
            <View style={styles.sub}>
              <Text>DM:</Text>
              <Switch
                trackColor={{false: '#767577', true: '#81b0ff'}}
                thumbColor={isDMEnabled ? '#f5dd4b' : '#f4f3f4'}
                ios_backgroundColor="#3e3e3e"
                onValueChange={toggleSwitchDM}
                value={isDMEnabled}
                disabled={!isPermissionSet}
              />
            </View>
          </View>
          <Separator />
          <Pressable style={styles.submit}
          onPress={save}>
            <Text>Opslaan</Text>
          </Pressable >
        </View>
      </div>
  )

    );


    async function subscribe() {
      alert('Subscribe ticked!');
      //if(OneSignal.Notifications) {
        await OneSignal.Notifications.requestPermission(true);
        setIsPermissionSet(OneSignal.Notifications.permission);
        alert(OneSignal.Notifications.permission);
      //}
      

    }





    function sendTestNotification() {

      
  

     try {
     var AuthorizationHeader = 'Bearer ' + keycloak.token; 
     var user = OneSignal.User;
     var id = user.PushSubscription.id;
     console.log(id);
   
      const apiUrl = process.env.REACT_APP_API_BASEURL+"/notification/sendtest/"+id;
       fetch(apiUrl, {
           method: 'GET',
           withCredentials: true,
           //credentials: 'include',
           headers: {
               'Authorization': AuthorizationHeader,
               'Content-Type': 'application/json'
           }});
       } catch (error){
         console.error(error);
      
       }
   }

    async function save() {
      OneSignal.User.addTag('verjaardag', isVerjaardagenEnabled.toString());
      OneSignal.User.addTag('nieuws', isNieuwsEnabled.toString());
      OneSignal.User.addTag('dm', isDMEnabled.toString()  );
    }
  }
    
  const styles = StyleSheet.create({
    container: {
      flex: 1,
      alignItems: 'left',
      justifyContent: 'left',
      backgroundColor: 'lime',
      width: '400px',
      backgroundColor: 'var(--thirth-bg-color)',
      border: '1px #361d2e',
      borderRadius: '5px',
      boxShadow: '0 4px 12px 0 #00000012, 0 2px 4px #0000000d',
      margin: '10px',
      opacity: '95%',
      padding: '10px',
      flexDirection: 'column',
    },
    sub: {
      width: '100%',
      justifyContent: 'space-between',
      flexDirection: 'row',
    },
    submit: {
      height: '50px',
      width: '125px',
      alignItems: 'center',
      justifyContent: 'center',
      elevation: 3,
      marginTop: 15,
      textAlign: 'center',
      margin: '10px',
      border: 'none',
      borderRadius: '10px',
      textAlign: 'center',
      backgroundColor: 'green',
      padding: '5px',
    },  
    separator: {
      marginVertical: 8,
      borderBottomColor: '#737373',
      borderBottomWidth: StyleSheet.hairlineWidth,
      width: '100%',
    },
    smallText: {
      fontSize: 'small',
    }
  });



