import { useKeycloak } from '@react-keycloak/web';
import React from 'react';

 const Authorized = (roles) => {
    const {keycloak} = useKeycloak();
    console.log(roles);
    const isAutherized = (roles) => {
        
        if (keycloak && roles && keycloak.authenticated) {
            return roles.some(r => {
                const realm =  keycloak.hasRealmRole(r);                
                return realm;
            });       
        }
        return false;
    }
    //console.log("Client is authorised: " + isAutherized());

    
    if(!isAutherized(roles) ) {
        return false;
    }                     
                    
    return true;
}

export default Authorized;