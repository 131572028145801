import React, { useEffect, useState } from "react";
import { useKeycloak } from '@react-keycloak/web';

import Moment from 'moment';
import 'moment/locale/nl';

import BezoekAdd from  './BezoekAdd';


import GezinsledenList from "./GezinsledenList";

function Bezoeken(adres) {
  //const [currentAdres, setCurrentAdres] = useState(null);  
  const [bezoeken, setBezoeken] = useState(null);
  //setCurrentAdres(adres.adres);
    console.log('loaded with adresId' + adres);
    console.log(adres);
    useEffect(() => {
      try {
        console.log('useEffect getBezoeken');
        const loadBezoeken = async() => { getBezoeken(adres.adres.Id); };
     
        loadBezoeken();
      } catch (error){
        console.error(error);
        setBezoeken(null);
      }
    },[adres]);

    
 const {keycloak} = useKeycloak();

 const getBezoeken = (adresId) => {
  console.log('getBezoeken');
     try {
     var AuthorizationHeader = 'Bearer ' + keycloak.token; 

      const apiUrl = process.env.REACT_APP_API_BASEURL+"/leden/"+adresId+"/bezoeken";
       fetch(apiUrl, {
           method: 'GET',
           withCredentials: true,
           //credentials: 'include',
           headers: {
               'Authorization': AuthorizationHeader,
               'Content-Type': 'application/json'
           }}).then((res) => res.json())
         .then((repos) => {
            console.log(repos);
            console.log(adresId);
            setBezoeken(repos);

         });
       } catch (error){
         console.error(error);
         setBezoeken(null);
       }
 };

 function checkResponse(bezoeken) {
   
  }
  return (<div className='d-flex flex-fill'>
    <div className='details'> 
          <h4>Adres:</h4>
          {adres.adres.Voorletters} {adres.adres.Tussenvoegsel ? adres.adres.Tussenvoegsel+ ' ': ''}{adres.adres.Achternaam}<br />
          {adres.adres.Adres}<br />
          {adres.adres.Postcode} {adres.adres.Plaats}<br /><br />
          <b>(Doop)leden op dit adres:</b><br />
          <GezinsledenList id={adres.adres.Id} /><br />
          <b>Wijkouderling: </b>{adres.adres.Wijkouderling}<br />
          <b>Wijkdiaken: </b>{adres.adres.Wijkdiaken}<br />
      </div>
      <div className='details'> 
        <h4>Bezoeken:</h4>
        {bezoeken?.map(item =>
       <p id={item.Id}>{Moment(item.Datum).format("dddd DD MMMM YYYY") } - {item.Bijbelgedeelte} - {item.Bijzonderheden}</p>

      )
      
      }<br />
      </div>
<div className='details'> 
      <b>Nieuw bezoek toevoegen</b><br />
      <BezoekAdd adres={adres.adres}/>

    </div></div>);
}

export default Bezoeken

 