import React from "react";

export default function Welkom() {
  
  
  return (
      <div className="page">
        <div className="title"> <h1>Ledenadministratie</h1></div>
        <div className="content">         
           <b><p>Welkom op de ledenadministratieomgeving. </p></b>
          <p>Deze applicatie kunt u gebruiken voor de ledenadministratie.</p>
          <div className="notificationSettings">
            Jarigen in uw wijk:
            
            </div>
        </div>
      </div>

    );
  }