import React, { useState }  from "react";
import { Sidenav, Nav, Toggle } from 'rsuite'; 
import DashboardIcon from '@rsuite/icons/legacy/Dashboard';
import MemberIcon from '@rsuite/icons/Member';
import GroupIcon from '@rsuite/icons/legacy/Group';
import GearCircleIcon from '@rsuite/icons/legacy/GearCircle';
import EventDetailIcon from '@rsuite/icons/EventDetail';
import OffIcon from '@rsuite/icons/Off';

import { useKeycloak } from '@react-keycloak/web';

export default function SidenavBar() {
  const [expanded, setExpanded] = useState(true);
  const {keycloak} = useKeycloak();

  return(  <div>
    <Sidenav  expanded={expanded} defaultOpenKeys={['3', '4']}>
    
      <Sidenav.Body>
        
        <Nav activeKey="1">
          <Nav.Item eventKey="1" icon={<DashboardIcon />}  href="/">
            Home
          </Nav.Item>
          <Nav.Item eventKey="2" icon={<MemberIcon />} href="/adressen">
            Adressen
          </Nav.Item>
          <Nav.Item eventKey="2" icon={<GroupIcon />} href="/Leden">
            Leden
          </Nav.Item>
          <Nav.Item eventKey="2" icon={<EventDetailIcon />} href="/Bezoeken">
            Bezoeken
          </Nav.Item>
          <Nav.Menu eventKey="4" title="Instellingen" icon={<GearCircleIcon />}>
            <Nav.Item eventKey="4-1"  href="/instellingen/notificaties">Notificaties</Nav.Item>
            <Nav.Item eventKey="4-1">Wijken</Nav.Item>                      
          </Nav.Menu>

        </Nav> 
        <Nav>
          <Nav.Item eventKey="2" icon={<OffIcon />} onClick={() => keycloak.logout()}>
            Afmelden
          </Nav.Item>
          </Nav>
      
      </Sidenav.Body>
      
      <Sidenav.Toggle onToggle={expanded => setExpanded(expanded)} />
    </Sidenav>
    
 
  </div> );
}
