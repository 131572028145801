import { BrowserRouter, Routes, Route } from "react-router-dom";

import { useKeycloak } from '@react-keycloak/web'

import { PrivateRoute } from "./utilities/PrivateRoute";

import Home from './/Home';
import Leden from './/Leden';
import Adressen from './/Adressen';
import Instellingen from './/Instellingen'

export const AppRouter = () => {
    const {initialized, keycloak} = useKeycloak();
  
    if (!initialized) {
      
        return <div className="loading"><div className="lds-ring"></div></div>
      }

return(
   
        
    <BrowserRouter>
        <Routes>  

            <Route path="/">
                <Route index element={<Home />} />
            </Route>   
            <Route path="/Home">
                <Route index element={<Home />} />
            </Route>   
             <Route path="/Leden" element={
                <PrivateRoute roles={['Kerkenraad']} >
                    <Leden />
                </PrivateRoute>
            }
            />   
             <Route path="/Adressen" element={
                <PrivateRoute roles={['Kerkenraad']} >
                    <Adressen />
            </PrivateRoute>
            }
            />   
             <Route path="/Instellingen" element={
                <PrivateRoute roles={['Kerkenraad']} >
                    <Instellingen />
            </PrivateRoute>
            }
            />     
            <Route path="/Instellingen/notificaties" element={
                <PrivateRoute roles={['Kerkenraad']} >
                    <Instellingen />
            </PrivateRoute>
            }
            />     
        </Routes>
    </BrowserRouter>
    );
};