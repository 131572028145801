import React from "react";

export default function NoAccess() {
  
  
  return (
      <div className="page">
        <div className="title"> <h1>Geen toegang</h1></div>
        <div className="content">         
           <b><p>Welkom op de ledenadministratieomgeving. U dient in te loggen om gebruik te maken van deze applicatie.</p></b>
          <p>Bent u ingelogd en ziet u deze melding? Mogelijk heeft u dan niet de juiste rechten om deze applicatie te gebruiken. Neem dan contact op met de beheerder.</p>
        </div>
      </div>

    );
  }