import React, { useEffect, useState, useRef  } from "react";
import { useNavigate  } from "react-router-dom";
import {StyleSheet, Text, View, TextInput, TextInputProps, Pressable} from 'react-native';
import { useKeycloak } from '@react-keycloak/web';

import { Bezoek } from "./Bezoek";

const styles = StyleSheet.create({
  
  container: {
    display: 'flex',
    width: '250px',
  },
  inputWrap: {
    flex: 1,
    flexDirection: "row",
    borderColor: "#cccccc",
    justifyContent: 'center',
    borderBottomWidth: 1,
    marginBottom: 10
  },

  textInput: {
    backgroundColor: "#FFF",
  },

  submit: {
    height: '30px',
    width: '100px',
    alignItems: 'center',
    justifyContent: 'center',
    elevation: 3,
    marginTop: '5px',
    textAlign: 'center',
    margin: '0px',
    border: 'none',
    borderRadius: '5px',
    backgroundColor: 'green',
    padding: '5px',
  },  

  text: {
    fontSize: 16,
    color: 'white',
    fontStyle: 'bold',
  },

  delete: {
    height: '30px',
    width: '100px',
    alignItems: 'center',
    justifyContent: 'center',
    elevation: 3,
    marginTop: 10,
    textAlign: 'center',
    border: 'none',
    borderRadius: '5px',
    textAlign: 'center',
    backgroundColor: 'red',
    padding: '5px',
  },



 
});

export default function BezoekAdd(adres) {
  const {keycloak} = useKeycloak();
  const apiBaseUrl = process.env.REACT_APP_API_BASEURL; 
  const apiUrl = process.env.REACT_APP_API_BASEURL+"/leden/"+adres.adres.Id+"/bezoeken/add";

  const navigate = useNavigate()
  const [bezoekRec, setBezoek] = useState(new Bezoek());
  const [submitted, setSubmitted] = useState(false);

  const datumRef  = useRef(null);
  const bijbelgedeelteRef  = useRef(null);
  const bijzonderhedenRef = useRef(null);
  //var bezoekRec = new Bezoek();
  //  setBezoek(bezoekRec);

  useEffect(() => {
    
   // bezoekRec = new Bezoek();
   // bezoekRec.Datum = null;
   // bezoekRec.Bijbelgedeelte = null;
   // bezoekRec.Bijzonderheden = null; setSubmitted(false);
    console.log(bezoekRec);
  }, [bezoekRec]);
  
  function saveBezoek() {
    //validate
    if(bezoekRec.Datum === null) {
      alert('Datum is een verplicht veld.')
      return;
    }


    try {
    var AuthorizationHeader = 'Bearer ' + keycloak.token; 

    
      fetch(apiUrl, {
          method: 'POST',
          withCredentials: true,
        
          headers: {
              'Authorization': AuthorizationHeader,
              'Content-Type': 'application/json'
          },
          body: JSON.stringify(bezoekRec)
          },
          clearForm(),
          
          alert('Wijzigingen zijn opgeslagen.'),
          //navigate("/Leden/Adressen")
      )
    } catch(error) {
      console.log(error);
      alert('Opslaan is mislukt')
    }
  }
  
  function clearForm() {
   // setBezoek(bezoekRec);
   setBezoek({...bezoekRec, Datum: null, Bijbelgedeelte: null});

   
    if (datumRef.current) {
     setNativeValue(datumRef.current, '');
     datumRef.current.dispatchEvent(new Event('input', { bubbles: true }));
   }
   if (bijbelgedeelteRef.current) {
    setNativeValue(bijbelgedeelteRef.current, '');
    bijbelgedeelteRef.current.dispatchEvent(new Event('input', { bubbles: true }));
  }
  if (bijzonderhedenRef.current) {
    setNativeValue(bijzonderhedenRef.current, '');
    bijzonderhedenRef.current.dispatchEvent(new Event('input', { bubbles: true }));
  }
    setSubmitted(true);      
}

  
function setNativeValue(element: HTMLInputElement, value: string) {
  const valueSetter = Object.getOwnPropertyDescriptor(element, 'value')?.set;
  const prototype = Object.getPrototypeOf(element);
  const prototypeValueSetter = Object.getOwnPropertyDescriptor(prototype, 'value')?.set;

  if (valueSetter && valueSetter !== prototypeValueSetter) {
    prototypeValueSetter?.call(element, value);
  } else {
    valueSetter?.call(element, value);
  }
}
  


    return <div>
    <View style={styles.container}> 
      <label>Datum: </label><TextInput  ref={datumRef} clearable='true' value={bezoekRec.Datum} style={styles.textInput} editable maxLength={100} name="Datum" label="Datum" onChangeText={newText => bezoekRec.Datum = newText}></TextInput>
      <label>Bijbelgedeelte: </label><TextInput  ref={bijbelgedeelteRef} clearable='true' value={bezoekRec.Bijbelgedeelte}  style={styles.textInput} name="Bijbelgedeelte" label="Bijbelgedeelte" onChangeText={newText => bezoekRec.Bijbelgedeelte = newText}></TextInput>
      <label>Bijzonderheden: </label><TextInput   ref={bijzonderhedenRef} clearable='true' style={styles.textInput} name="Bijzonderheden" label="Bijzonderheden" onChangeText={newText => bezoekRec.Bijzonderheden = newText} multiline numberOfLines={4}></TextInput>
      <Pressable onPress={saveBezoek} style={styles.submit}><Text style={styles.text}>Toevoegen</Text></Pressable>
    </View>
    
    </div>

    
}

