import React, { useEffect, useState } from "react";
import { useNavigate  } from "react-router-dom";
import {StyleSheet, Text, View, TextInput, Pressable} from 'react-native';
import { Picker } from '@react-native-picker/picker';
import { useKeycloak } from '@react-keycloak/web';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


export default function GezinsledenList({id}) {
  const {keycloak} = useKeycloak();
  const apiBaseUrl = process.env.REACT_APP_API_BASEURL; 

  function load(id){
    var AuthorizationHeader = 'Bearer ' + keycloak.token; 

    const apiUrl = apiBaseUrl+"/leden/"+id+"/gezinsleden";
      try {
      fetch(apiUrl, {
          method: 'GET',
          withCredentials: true,
        
          headers: {
              'Authorization': AuthorizationHeader,
              'Content-Type': 'application/json'
          }}).then((res) => res.json())
        .then((repos) => {
          setGezinsleden(repos); 
                
        });
      } catch {}
  }
  

  const navigate = useNavigate()

  const [gezinsleden, setGezinsleden] = useState();
  
  useEffect(() => {

    load(id);
  }, [id]);
  
 
    return <p>

      {gezinsleden?.map((gezinslid) => (
<span>
        {gezinslid.Lidtype === 'Dooplid' ? <i> {gezinslid.Weergavenaam}  </i>: gezinslid.Weergavenaam  }
        </span>

      ))}
    
    </p>

    
}

