import { useKeycloak } from '@react-keycloak/web';
import React from 'react';
import { Navigate} from 'react-router-dom';

export const PrivateRoute = ({ children, roles, ...rest }) => {
    const {keycloak} = useKeycloak();
    //console.log(roles);
    const isAutherized = (roles) => {
        
        if (keycloak && roles && keycloak.authenticated) {
            return roles.some(r => {
                const realm =  keycloak.hasRealmRole(r);                
                return realm;
            });       
        }
        return false;
    }
    //console.log("Client is authorised: " + isAutherized());

    
    if(!isAutherized(roles) ) {
        return <Navigate to="/" replace={true} />
    }                     
                    
    return children;
}