import React, { useEffect, useState } from "react";

import 'moment/locale/nl';
import { useKeycloak } from '@react-keycloak/web';



function Ledenlijst() {
  const [leden, setLeden] = useState(null);
 
  useEffect(() => {
    try {
      const loadLeden = async() => { getLeden(); };
   
      loadLeden();
    } catch (error){
      console.error(error);
      setLeden(null);
    }
  },[]);




 const {keycloak} = useKeycloak();

  const getLeden = () => {

      try {
      var AuthorizationHeader = 'Bearer ' + keycloak.token; 

       const apiUrl = process.env.REACT_APP_API_BASEURL+"/leden";
        fetch(apiUrl, {
            method: 'GET',
            withCredentials: true,
            //credentials: 'include',
            headers: {
                'Authorization': AuthorizationHeader,
                'Content-Type': 'application/json'
            }}).then((res) => res.json())
          .then((repos) => {
            setLeden(repos);
            //console.log(repos);
           // setAppState({ loading: false, repos: repos });
          });
        } catch (error){
          console.error(error);
          setLeden(null);
        }
  };
 
  function checkResponse(leden) {

    var header = <tr><th>Naam</th><th>Adres</th><th>Naam</th></tr>    
    var filterRow =  <tr><th>-</th><th>-</th><th>-</th></tr>

    var canEdit = false;
    canEdit = keycloak.hasRealmRole('Ledenadmin');

    if (leden && leden.length >0) {
        return <table>{header}
          
            {filterRow}
            <br />
            <div  id='leden' className='leden'>
            { leden?.map(item => 
      
      <div key={item.Id} id={item.Id} className="ledenCard">
        <div key={'details-'+item.Id}  id={'details-'+item.Id}  className="ledenCardDetails"><b>{item.Voorletters} {item.Tussenvoegsel === null ? "" : (item.Tussenvoegsel + ' ')}{item.Achternaam}</b></div>
        <div key={'details-edit-'+item.Id} id={'details-edit-'+item.Id} className="ledenCardDetailsEdit">{canEdit ? <a  key={'details-edit-'+item.Id} href={'/Leden/Edit/'+item.Id}>Wijzigen</a> : ''}</div>
        <br />
        {item.Adres} <br />
        {item.Postcode} {item.Plaats} <br />
        {item.Telefoon} 

      </div>
      
      )} </div>
        
        </table>
    } else {
        return <><table>{header}{filterRow}</table><div className='noResult'>Geen leden gevonden.</div></>;
    }

  }
 
  return (<div>
    {checkResponse(leden)}
    </div>
  );
}
 
export default Ledenlijst;