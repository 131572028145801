import React, { useEffect, useState } from "react";
import {StyleSheet, Text, View, TextInput, Pressable} from 'react-native';

import 'moment/locale/nl';
import { useKeycloak } from '@react-keycloak/web';

import  {FaSortAlphaDown, FaSortAlphaUp}  from "react-icons/fa";

import Bezoeken from "./Bezoeken";


function Adreslijst() {
  const [leden, setLeden] = useState(null);
  const [filteredList, setFilteredlist] = useState(null);

  const[achternaamFilter, setAchternaamfilter] = useState(null);
  //const[voornaamFilter, setVoornaamfilter] = useState(null);

  const[record, setRecord] = useState(null);
 
  useEffect(() => {
    try {
      setAchternaamfilter('');
      //setVoornaamfilter('');
      const loadLeden = async() => { getLeden(); };
   
      loadLeden();
    } catch (error){
      console.error(error);
      setLeden(null);
    }
  },[]);

  useEffect(() => {
    console.log('A filter criteria has changed')
    filter();
  },[achternaamFilter,
    // voornaamFilter
    ]);


  useEffect(() => {
    checkResponse(filteredList)
  },[filteredList]);

  useEffect(() => {
    console.log(record);
  },[record])

 const {keycloak} = useKeycloak();

  const getLeden = () => {

      try {
      var AuthorizationHeader = 'Bearer ' + keycloak.token; 

       const apiUrl = process.env.REACT_APP_API_BASEURL+"/leden";
        fetch(apiUrl, {
            method: 'GET',
            withCredentials: true,
            //credentials: 'include',
            headers: {
                'Authorization': AuthorizationHeader,
                'Content-Type': 'application/json'
            }}).then((res) => res.json())
          .then((repos) => {
            setLeden(repos);
            setFilteredlist(repos);
            //console.log(repos);
           // setAppState({ loading: false, repos: repos });
          });
        } catch (error){
          console.error(error);
          setLeden(null);
        }
  };

  const filterAchternaam = (text) => {
    setAchternaamfilter(text);
    filter();
  }

  //const filterVoornaam = (text) => {
  // setVoornaamfilter(text);
  //  filter();
  //}

  function filter(){
    const initiallist = leden;
    if(!initiallist){
      return;
    }
    var workList = [...initiallist];
    workList = workList.filter(adres => achternaamFilter !== '' ? adres.Achternaam.toLowerCase().includes(achternaamFilter.toLowerCase()) : true);
    //workList = workList.filter(adres => voornaamFilter !== '' &&  adres.Voornaam !== undefined ? adres.Voornaam.toLowerCase().includes(voornaamFilter.toLowerCase()) : true);
    setFilteredlist(workList);
  }
 
  function sortByName(direction){
    console.log('sorting');
    console.log('direction: '+direction);
    const initiallist = filteredList;
    if(!initiallist){
      return;
    }
    var workList = [...initiallist];
    console.log(workList);
    if(direction === 'asc') {
      workList.sort((a, b) => a.Achternaam.localeCompare(b.Achternaam));
    } else {
      workList.sort((a, b) => a.Achternaam.localeCompare(b.Achternaam)).reverse();
    }
    console.log(workList);
    setFilteredlist(workList)
  }

  function checkResponse(leden) {

    var header = 
    <tr>
      <th width='15%'>Achternaam</th>
      <th width='10%'>Tussenvoegsel</th>
      <th width='5%'>Voorletters</th>
      <th width='20%'>Adres</th>
      <th width='10%'>Postcode</th>
      <th width='10%'>Plaats</th>
      <th width='20%'>Telefoon</th>
      </tr>    
    var filterRow =  
    <tr>
      <td>
        <TextInput style={{height: 20, backgroundColor:'#FFF'}} value={achternaamFilter} onChangeText={filterAchternaam}></TextInput>
        <FaSortAlphaDown onClick={() => sortByName('asc')} />
          <FaSortAlphaUp onClick={() => sortByName('desc')} />
      </td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      </tr>

    var canEdit = false;
    canEdit = keycloak.hasRealmRole('Ledenadmin');

    if (leden && leden.length >0) {
        return <div className='screen'><div className='header'><table width='100%'> 
            {header}
          
            {filterRow}
            </table></div>
            <div className='master'>
        	<table  width='100%'>
            {leden?.map(item =>
                <tr key={'details-'+item.Id} onClick={() => setRecord(item)} className={item === record ? 'selected' : ''}>
                  <td width='15%'>{item.Achternaam}</td>
                  <td width='10%'>{item.Tussenvoegsel === null ? "" : item.Tussenvoegsel}</td>
                  <td width='5%'>{item.Voorletters}</td>
                  <td width='20%'>  {item.Adres} </td>
                  <td width='10%'>{item.Postcode}</td>
                  <td width='10%'> {item.Plaats}</td>
                  <td width='20%'>{item.Telefoon} </td>
                </tr>

       )
      }
        </table></div>
        
        <div className='detail'>
          {record ? <Bezoeken adres={record} /> : ''}
        </div>
        </div>
    } else {
        return <div className='screen'><div className='header'><table  width='100%'>
        {header}
      
        {filterRow} </table></div></div>;
    }

  }
 
  return (<div>
    {checkResponse(filteredList)}
    </div>
  );
}

export default  Adreslijst;