import React from "react";

export default function Instellingen() {
  
  
  return (
      <div>
        <div className="title"> <h1>Instellingen</h1></div>
        <div className="content">         
           <p>Op deze pagina kunt u uw persoonlijke voorkeuren voor het gebruik van de ledenadministratie instellen.</p>
        </div>
      </div>

    );
  }