import React from "react";
import { MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit';

import Authorized from "./utilities/Authorized";

import NoAccess from "./components/NoAccess";
import Navbar from "./components/Navbar";
import SidenavBar from "./components/SidenavBar";
import UserInfo from './components/UserInfo';

import InstellingenComp from "./components/Instellingen";
import NotificatieSettings from "./components/NotificatieSettings";
import Footer from "./components/Footer";

export default function Instellingen() {
  const neededRoles = ['Kerkenraad']
  var hasAccess = Authorized(neededRoles);

  return (        
   
<div className="page-container">
<MDBContainer fluid>

  <div className=" vh-100">
    <MDBCol size='12'>
    <div className="p-2 w-100 navwrapper bg-light"><Navbar /></div>
    </MDBCol>
      <div className="d-flex flex-row ">
        <div className="d-flex flex-column">            
          <div className="d-flex align-items-xl-stretch"><SidenavBar/></div>
        </div>
      <div className="p-2 flex-fill ">{hasAccess ? <div className="page"><InstellingenComp /><NotificatieSettings /></div>: <NoAccess />}</div>
    </div>
    </div>
    <MDBRow>
    <Footer />
    </MDBRow>
</MDBContainer>
</div>
  );
}