
import React from "react";

export default function Footer() {
  

  return (
    <div>
         <div className="d-flex flex-row footer"> 
            <div className="d-flex flex-column w-100 txt-normal center">
               Ledenadministratie - versie {process.env.REACT_APP_VERSION} - september 2024 - wijzigingen</div>
         
            </div>
    </div>
    );
}